const ExpressJsIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        d="M509.2,112.6h-8.7c-10.8,0-20.9,5-27.4,13.6L384,242.7l-89.1-116.5c-6.5-8.5-16.7-13.6-27.4-13.6h-8.7l112.3,146.9
	L256.4,409.6h8.7c10.8,0,20.9-5,27.4-13.6L384,276.4L475.5,396c6.5,8.5,16.7,13.6,27.4,13.6h8.7L396.9,259.5L509.2,112.6z
	 M218,350.6c-26.2,39.7-77.6,60.1-126.9,42.1C47.8,377,20.5,333.9,20.5,287.8v-11.4h122.9l0,0H256v-42.3
	c0-66.6-49.3-124.9-115.7-131.2C64.2,95.8,0,155.8,0,230.4v57.1c0,55,32.9,106.1,84.7,124.8c67.6,24.3,138.7-12,162.8-74.3l0,0h-6.5
	C231.6,337.9,223.1,342.8,218,350.6z M20.5,230.4c0-59.3,48.2-107.5,107.5-107.5s107.5,48.2,107.5,107.5V256h-215V230.4z"
      />
    </svg>
  );
};

export default ExpressJsIcon;
