const MongoDbIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <path
          fill="#599636"
          d="M254.4,1.4l13.7,25.7c3.1,4.7,6.4,8.9,10.3,12.8c11.4,11.4,22.3,23.4,32.1,36.3
		c23.2,30.4,38.8,64.2,49.9,100.7c6.7,22.3,10.3,45.2,10.6,68.3c1.1,69.2-22.6,128.6-70.4,177.9c-7.8,7.8-16.2,15-25.1,21.5
		c-4.7,0-7-3.6-8.9-7c-3.6-6.1-5.9-13.1-7-20.1c-1.7-8.4-2.8-16.7-2.2-25.4v-3.9C256.9,387.3,252.7,3.4,254.4,1.4z"
        />
        <path
          fill="#6CAC48"
          d="M254.4,0.6c-0.6-1.1-1.1-0.3-1.7,0.3c0.3,5.6-1.7,10.6-4.7,15.4c-3.4,4.7-7.8,8.4-12.3,12.3
		c-24.8,21.5-44.3,47.4-59.9,76.4c-20.8,39-31.5,80.9-34.6,124.9c-1.4,15.9,5,71.9,10,88.1c13.7,42.9,38.2,78.9,70,110.2
		c7.8,7.5,16.2,14.5,24.8,21.2c2.5,0,2.8-2.2,3.4-3.9c1.1-3.6,1.9-7.2,2.5-10.9l5.6-41.8L254.4,0.6z"
        />
        <path
          fill="#C2BFBF"
          d="M268.1,461.5c0.6-6.4,3.6-11.7,7-17c-3.4-1.4-5.9-4.2-7.8-7.2c-1.7-2.8-3.1-6.1-4.2-9.2
		c-3.9-11.7-4.7-24-5.9-36v-7.2c-1.4,1.1-1.7,10.6-1.7,12c-0.8,12.6-2.5,25.2-5,37.6c-0.8,5-1.4,10-4.5,14.5c0,0.6,0,1.1,0.3,2
		c5,14.8,6.4,29.8,7.2,45.2v5.6c0,6.7-0.3,5.3,5.3,7.5c2.2,0.8,4.7,1.1,7,2.8c1.7,0,2-1.4,2-2.5l-0.8-9.2v-25.7
		C266.7,470.1,267.5,465.7,268.1,461.5L268.1,461.5z"
        />
      </g>
    </svg>
  );
};

export default MongoDbIcon;
