const TailwindIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#06B0CD"
        d="M256,103.3c-68.3,0-110.9,33.9-128,101.8c25.6-33.9,55.5-46.6,89.6-38.2c19.5,4.8,33.4,18.9,48.8,34.4
	C291.5,226.7,320.6,256,384,256c68.3,0,110.9-33.9,128-101.8c-25.6,33.9-55.4,46.6-89.6,38.2c-19.5-4.8-33.4-18.9-48.8-34.4
	C348.5,132.6,319.5,103.3,256,103.3z M128,256C59.7,256,17.1,289.9,0,357.8c25.6-33.9,55.5-46.6,89.6-38.2
	c19.5,4.8,33.4,18.9,48.8,34.4c25.1,25.3,54.1,54.6,117.6,54.6c68.3,0,110.9-33.9,128-101.8c-25.6,33.9-55.5,46.6-89.6,38.2
	c-19.5-4.8-33.4-18.9-48.8-34.4C220.5,285.3,191.4,256,128,256z"
      />
    </svg>
  );
};

export default TailwindIcon;
