const ReduxIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <path
          fill="#7247B5"
          d="M354,352.8c18.2-1.8,32.3-17.1,32.3-35.6c0-0.7,0-1.5-0.1-2.2v0.1c-1-19.7-17-35.2-32.9-35.4h-1.3
		c-19.8,0.7-35.5,16.9-35.5,36.8c0,0.4,0,0.9,0,1.4v-0.1c0.5,9.7,4.5,18.3,10.7,24.8l0,0C303,387.7,266,423.3,221.1,445.2l-1.4,0.6
		c-24.3,13.5-53.3,21.4-84.2,21.4c-7.8,0-15.5-0.5-23.1-1.5l0.9,0.1c-27.8-2.7-51.7-17.2-66.9-38.4l-0.2-0.3
		c-11-15.4-17.5-34.6-17.5-55.3c0-17.1,4.4-33.1,12.3-47l-0.2,0.5c11.2-21.1,26.4-38.7,44.8-52.3l0.4-0.3
		c-3.5-9.2-6.6-20.4-8.8-31.8l-0.2-1.3C-18.7,307.8-9,401.3,20,445.4c25.4,32.4,64.5,53,108.4,53c2,0,4,0,6-0.1h-0.3
		c0.7,0,1.6,0,2.4,0c13.2,0,26-1.5,38.4-4.4l-1.1,0.2c81.8-15,148.8-67.7,183.1-139l0.7-1.5L354,352.8z"
        />
        <path
          fill="#7247B5"
          d="M469.1,272.3c-49-55.9-120.6-91-200.4-91c-2.4,0-4.9,0-7.3,0.1h0.4h-11c-5.8-11.6-17.5-19.4-31.1-19.4
		c-0.4,0-0.8,0-1.2,0h0.1h-0.8c-19.7,0.3-35.6,16.3-35.6,36.1c0,0.6,0,1.2,0,1.7v-0.1c0.9,19.7,17,35.3,36.7,35.5h1.6
		c14.6-0.6,26.9-9.6,32.2-22.3l0.1-0.2h11.9c52.1,0.1,100.5,16.1,140.5,43.4l-0.9-0.6c31.2,20,55.4,48.7,69.2,82.7l0.4,1.2
		c5.3,11.1,8.4,24,8.4,37.7c0,14.5-3.4,28.2-9.6,40.2l0.2-0.5c-15.9,32.4-48.5,54.2-86.3,54.2c-1.4,0-2.8,0-4.1-0.1h0.2
		c-23.2-0.5-45.1-5.6-65-14.4l1.1,0.4c-7.7,6.4-20.6,17.1-30,23.5c24.8,12.2,53.8,19.6,84.5,20.3h0.2c1.2,0,2.6,0.1,4,0.1
		c52.1,0,97.7-27.6,123-69.1l0.4-0.6c7-16.5,11-35.8,11-56c0-40.4-16.2-77-42.5-103.6l0,0l-0.3,0.7L469.1,272.3z"
        />
        <path
          fill="#7247B5"
          d="M135.7,364.4c0.9,19.7,17,35.3,36.8,35.5h1.3c19.8-0.7,35.5-16.9,35.5-36.7c0-20.2-16.3-36.6-36.5-36.8
		h-1.4c-0.2,0-0.4,0-0.7,0c-1.5,0-2.9,0.2-4.3,0.7l0.1,0c-21.8-35.5-34.8-78.6-34.8-124.6c0-7.4,0.3-14.8,1-22l-0.1,0.9
		c1.9-38.9,16.1-74.1,38.9-102.2l-0.2,0.3c20.2-21.9,48.7-35.8,80.5-36.8h0.2c69.6-1.3,98.7,85.5,100.9,119.9l32.3,9.7
		C377.9,66.6,312.1,11.1,249.2,11.1c-63.9,3.3-116.9,46.3-135.1,104.7l-0.3,1.1c-8.9,25.8-14.1,55.4-14.1,86.3
		c0,52.8,15.1,102.1,41.2,143.7l-0.7-1.1c-2.9,4.4-4.7,9.8-4.7,15.6c0,1.1,0.1,2.1,0.2,3.2L135.7,364.4L135.7,364.4z"
        />
      </g>
    </svg>
  );
};

export default ReduxIcon;
