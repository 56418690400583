const SassIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#C76395"
        d="M440.8,284.8c0.6,0,1.4,0,2.1,0c13.4,0,26,3.1,37.2,8.6l-0.5-0.2c10.2,5,18.6,12.3,24.7,21.4l0.1,0.2
	c4.8,7,7.6,15.6,7.6,24.9c0,0.7,0,1.3,0,2v-0.1c-0.5,9.3-4.7,17.4-11.2,23.2l0,0c-3.5,3.5-7.8,6.2-12.6,7.9l-0.2,0.1
	c-2.7,0.5-4.1,0.1-4.4-1.2c-0.3-1.3,0.9-2.8,3.6-4.4c7.2-4.4,12.5-11.5,14.4-19.8l0-0.2c0-0.7,0.1-1.5,0.1-2.3c0-5-1.2-9.7-3.3-13.8
	l0.1,0.2c-3.5-7.3-8.7-13.2-15-17.5l-0.2-0.1c-9.6-6.1-21-10.2-33.3-11.2l-0.3,0c-4.2-0.6-9-1-14-1c-12.8,0-25.1,2.4-36.3,6.8
	l0.7-0.2c2.3,5.4,3.8,11.7,4,18.3v0.1c-0.3,13.5-7.5,25.3-18.3,31.9l-0.2,0.1c-5,3.5-10.6,6.6-16.7,9l-0.6,0.2
	c-3.7,1.8-8,2.8-12.5,2.8c-0.5,0-1,0-1.6,0h0.1c-10.1-2.1-12.5-10.9-7.2-26.4c2.3-6.7,5.6-12.6,9.7-17.7l-0.1,0.1
	c5.3-6.9,11-12.9,17.4-18.3l0.2-0.1l-1.6-2.3c-2.9-5-5.6-10.8-7.8-16.9l-0.2-0.7c-1.6-5.3-2.9-10.4-4-15.2l-0.8-5.6l-8,15.2
	c-6.9,12.8-13.6,24-20,33.6l-2.4,4c2.7,6.5,4.7,14.1,5.6,22l0,0.4c0.1,0.8,0.1,1.6,0.1,2.5c0,12.1-6,22.9-15.2,29.4l-0.1,0.1
	c-4.4,2.7-9.6,5.5-15,8l-1,0.4c-5.7,2.4-12.2,4-19.1,4.4h-0.1c-0.5,0-1.2,0.1-1.8,0.1c-4,0-7.8-0.9-11.2-2.5l0.2,0.1
	c-2-1.7-3.3-4.3-3.3-7.2c0-1.5,0.3-2.8,0.9-4.1l0,0.1c6.4-9,12.7-16.8,19.4-24.3l-0.2,0.2l17.6-22.4l-3.2-7.2c-2.6-4.7-5-10.3-7-16
	l-0.2-0.8c-1.6-5.3-2.9-10.4-4-15.2l-0.8-5.6l-8,19.2c-6.4,14.9-12.3,27.7-17.6,38.4c-8,16-14.6,28.3-20,36.8l-1.6,2.4
	c-6.4,10.1-12.5,15.2-18.4,15.2s-9.9-3.7-12-11.2c-1.5-5.5-2.4-11.8-2.4-18.4c0-0.6,0-1.2,0-1.7v0.1l1.6-11.2v0.8
	c-4.3,10.1-8.5,19.5-12.8,28c-2.8,5.5-5.7,10.3-9,14.7l0.2-0.3c-3.3,3.5-7.9,5.6-13.1,5.6c0,0-0.1,0-0.1,0c-0.3,0-0.7,0-1.1,0
	c-4,0-7.7-1.5-10.5-4l0,0c-5.6-5.7-9.7-12.9-11.9-20.9l-0.1-0.3c-2.6-7.3-4.1-15.7-4.1-24.5c0-1.1,0-2.3,0.1-3.4v0.2
	c1.3-12.7,3.8-24.4,7.5-35.5l-0.3,1.1l-28.8,16.8v0.8c4.2,8.7,6.6,18.9,6.6,29.6c0,2-0.1,3.9-0.2,5.8l0-0.3
	c-0.8,16.5-6.1,31.6-14.6,44.3l0.2-0.3c-8.9,14.2-21.8,25.1-37,31.4l-0.5,0.2c-8.6,3.9-18.6,6.1-29.2,6.1c-6.7,0-13.2-0.9-19.3-2.6
	l0.5,0.1c-5.1-1.7-9.3-5.1-11.9-9.5l0-0.1c-3.7-5.4-6.1-12-6.5-19.1v-0.1c-0.2-1.6-0.3-3.5-0.3-5.4c0-8.2,1.9-16,5.2-22.9l-0.1,0.3
	c9.2-15.7,21.9-28.4,37.1-37.3l0.5-0.3c10.7-7.4,23.1-14.7,36-21l1.7-0.8l4-2.4c-4.8-3.7-13.6-10.1-26.4-19.2
	c-17.1-11.5-32-23-46-35.6l0.4,0.4c-13.6-11.7-23.4-27.3-27.9-45l-0.1-0.6c-0.4-2.5-0.7-5.3-0.7-8.2c0-9,2.3-17.5,6.4-24.9l-0.1,0.3
	c9-19.3,21.4-35.5,36.6-48.6l0.2-0.2c16.5-15.6,35.2-29.1,55.4-40.1l1.4-0.7c18.1-10.5,39.3-20.2,61.3-28l2.7-0.8
	c20.5-7.5,44.1-11.9,68.8-12h0c1.1,0,2.4-0.1,3.8-0.1c19.9,0,38.7,4.6,55.4,12.8l-0.7-0.3c14.4,6.6,25.2,18.9,29.5,34l0.1,0.4
	c1.3,5.2,2.1,11.1,2.1,17.2c0,10.1-2.1,19.8-5.9,28.5l0.2-0.5c-6.9,16.6-16.6,30.7-28.8,42.4l0,0c-12.3,12.2-27.1,22-43.5,28.5
	l-0.9,0.3c-17.2,7.4-37.2,12-58.1,12.8l-0.3,0c-3.1,0.4-6.6,0.5-10.2,0.5c-9.5,0-18.6-1.4-27.3-3.9l0.7,0.2
	c-7.7-2.5-14.4-6.3-20.1-11.3l0.1,0c-4-3.2-7.2-7.2-9.5-11.7l-0.1-0.2c-1.6-4.3-1.7-6.8-0.4-7.6c1.3-0.8,2.3-0.9,2.8-0.4l4,4
	c4.5,4.1,9.8,7.4,15.7,9.5l0.3,0.1c8.1,2.8,17.4,4.5,27.2,4.5c3.1,0,6.2-0.2,9.2-0.5l-0.4,0c25.9-3.1,49.3-11.9,69.4-25.2l-0.6,0.4
	c17-10.1,30.8-24.1,40.5-40.7l0.3-0.5c4.8-7.1,7.7-15.8,7.7-25.2c0-5.8-1.1-11.3-3.1-16.4l0.1,0.3c-6-9.5-15.7-16.1-27-18l-0.2,0
	c-8.8-2.2-18.9-3.4-29.2-3.4c-8.3,0-16.5,0.8-24.4,2.3l0.8-0.1c-27,4.9-51.1,13.2-73.4,24.7l1.5-0.7c-65.6,33.1-99.5,67-101.6,101.6
	v0.8c0,14,5.5,26.7,14.4,36l0,0c10.2,11.3,21.5,21.3,33.8,30l0.6,0.4c11.2,8.4,21.1,17,30.4,26.4l0,0l1.6,1.6l51.2-28
	c5.8-8.5,12.9-15.6,20.9-21.4l0.3-0.2c6.4-5.4,14.5-8.9,23.5-9.6l0.1,0c0.3,0,0.7,0,1.1,0c6,0,11.1,3.6,13.3,8.8l0,0.1
	c1.7,3.8,2.7,8.2,2.7,12.8c0,1.4-0.1,2.8-0.3,4.2l0-0.2l-1.6,7.9l2.4-1.6c1.7-1.3,3.8-2,6-2c1.4,0,2.8,0.3,4,0.8l-0.1,0
	c3.1,0.8,5.3,3.6,5.3,6.8c0,0.6-0.1,1.1-0.2,1.6l0,0l-2.4,8.8c-4.8,19.2-8,33.1-9.6,41.6c-0.8,2.8-1.2,6-1.2,9.3c0,0.7,0,1.4,0.1,2
	v-0.1c0.3,3.2,0.7,4.8,1.2,4.8c0.5,0,1.6-1.6,3.2-4.8l2.4-5.6c0.5,0,0.5,0.3,0,0.8l8.8-18.4c20.8-45.9,32-71.5,33.6-76.8l4.8-15.2
	c0.5-1.1,2.7-2.1,6.4-3.2c3.8-1,8.1-1.6,12.6-1.6c0.4,0,0.7,0,1.1,0h0c7.5,0,11.2,1.9,11.2,5.6l-0.8,4c-1.8,4.9-3.6,11.1-4.9,17.4
	l-0.2,1c-0.3,2.1-0.5,4.4-0.5,6.9c0,3.2,0.3,6.4,0.9,9.4l0-0.3l0.8,3.2c1.6,6.7,4.1,12.5,7.4,17.9l-0.2-0.3
	c6.4-10.7,12.5-21.6,18.4-32.8c4.6-7.7,9-16.7,12.4-26.1l0.4-1.1c1.2-6,2.6-11.1,4.3-16.1l-0.3,0.9c0.5-1.6,2.8-2.8,6.8-3.6
	c3.8-0.8,8.2-1.2,12.6-1.2h0.6h0c7.5,0,11.5,1.9,12,5.6l-1.6,3.2c-1.9,5.4-3.6,11.8-4.7,18.5l-0.1,0.7c-0.1,1.6-0.2,3.3-0.2,5.2
	c0,3.8,0.4,7.6,1.1,11.2l-0.1-0.4v3.2c1.8,7,4.6,13.1,8.2,18.7l-0.2-0.3l2.4,5.6c13.4-6.6,29.1-10.4,45.7-10.4L440.8,284.8
	L440.8,284.8L440.8,284.8z M97.6,400.9c11.1-11.5,18-27.2,18-44.5c0-6.7-1-13.1-2.9-19.2l0.1,0.4l-12.8,8
	c-13.1,7.4-24.4,15.8-34.6,25.2l0.1-0.1c-7.8,7.1-13.5,16.4-16.3,26.8l-0.1,0.4c-2.4,9.9-1.2,16.3,3.6,19.2c2.9,1.3,6.4,2.1,10,2.1
	c3.9,0,7.7-0.9,10.9-2.6l-0.1,0.1c9.5-3.5,17.6-8.9,24-16l0,0L97.6,400.9z M204.8,327.2c2.7-6.9,5.6-15.2,8.8-24.8
	c4.3-12.8,5.7-20.3,4.4-22.4c-1.3-2.1-3.9-2.4-7.6-0.8c-4.5,2.2-8.2,5.1-11.2,8.8l0,0.1c-3.3,4.2-6.5,8.9-9.3,13.9l-0.3,0.5
	c-3.2,5.3-6,11.4-7.9,17.9l-0.1,0.5c-3.1,8.4-4.9,18.1-4.9,28.1c0,1.4,0,2.7,0.1,4v-0.2c0.5,10.7,2.2,16.4,5.1,17.2
	c2.9,0.8,6.2-3.1,10-11.6c3.7-6.6,6.9-14.3,9.4-22.3l0.2-0.9C203.1,331.4,204.1,328.7,204.8,327.2L204.8,327.2z M293.5,369.6
	c11.1-5,18.9-15.5,20-27.9l0-0.1v-0.8l-8.8,11.2l-16,17.6c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.3,0.2,0.6,0.4,0.8
	C289.3,371.7,290.9,371.2,293.5,369.6L293.5,369.6z M362.4,354.4c12.1-3.5,20.8-14.4,20.8-27.4c0-0.2,0-0.4,0-0.6v0
	c-0.2-3.2-0.7-6.2-1.7-9.1l0.1,0.2c-6.2,5.5-11.3,12.2-15,19.6l-0.2,0.4C361.5,347.2,360.2,352.8,362.4,354.4L362.4,354.4z"
      />
    </svg>
  );
};

export default SassIcon;
