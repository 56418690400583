const RestfulApiIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <path
          d="M254.2,511.9c-9.3,0-18.6,0.3-27.8-0.1c-18.5-0.6-27.4-8.2-28.9-26.8c-1-12.3-6.5-17.6-17.6-22.9
		c-23.7-11.2-46.2-24.8-68.6-38.5c-6.7-4.1-11.5-6.7-19.9-3.8c-28.9,10-33.1,7.8-48.1-19.9C35,384.6,26.5,369.2,18,353.8
		c-6.5-11.6-4.4-21.8,6.1-29.2c15-10.5,16.1-22.9,13.8-40.6c-3-23,1-46.9,1.1-70.4c0-5.9-1.9-13.8-5.9-17.4
		c-21.9-19.9-23.1-24.3-8.7-49.5c8.3-14.5,16.4-29,25.2-43.2C60.3,86.4,66.2,83.3,85,90.4c13.4,5.1,21.3,0.7,32.1-6.5
		c20.3-13.4,42-25,64.2-35c9.5-4.2,13.8-9,15.6-18.4C202,4,206.7,0.4,234,0.1c16.2-0.1,32.5-0.2,48.7,0.1
		c19.8,0.4,28.4,6.9,30.9,26.4c1.6,13,8.3,18.2,19.4,23.6c23.1,11.3,45.4,24.4,67.2,38.2c7.5,4.8,13.3,6,21.5,3.2
		c25.7-8.6,31.3-6.2,44.6,17.5c8.2,14.5,16.8,28.8,24.9,43.4c9.8,17.8,9.6,24.9-6.1,37.3c-12.6,9.9-13.1,20.2-11.5,34.8
		c2.3,21.8,1.5,44.2-0.4,66.1c-1,12-0.4,20.6,9.4,29.2c18.3,16,18.5,20,6.6,41.6c-8.4,15.4-17.3,30.6-26.6,45.6
		c-11.5,18.6-17.8,21.3-37.7,13.9c-11.6-4.4-18.9-1.2-28.8,5.3c-21.5,14.1-44.9,25.3-67.1,38.4c-5.2,3.1-11.1,8.4-12.6,13.8
		c-8.1,30.4-11.4,33.4-42.6,33.5C267.2,511.9,260.7,511.9,254.2,511.9C254.2,511.9,254.2,511.9,254.2,511.9z M63.2,316.1
		c-3.3,3.3-7.6,8.5-12.7,12.5c-15,11.7-15.5,12.7-6.1,29.3c6.6,11.7,14.3,22.8,20.1,34.9c3.9,8,8.7,10,16.4,7.6
		c4.4-1.4,9.1-2.3,13-4.6c8.7-5,14.9-3,22.1,3.7c20.8,19.5,44.1,36.1,71.7,43.7c19.2,5.3,29.5,14.7,29.4,35.1
		c-0.1,8.1,4,12.2,13.2,11.6c14.8-0.8,29.7-1.2,44.5,0c13.9,1.1,20.7-2.9,20.3-17.8c-0.4-15.5,8.4-22,22.9-27.6
		c21.8-8.3,43.9-18.6,61.8-33.1c17.2-13.9,31.6-22.2,52.3-10.3c7.5,4.3,11.6,0.4,15.1-6.4c6.6-12.7,13.1-25.7,21.3-37.4
		c8.2-11.8,7-20.3-5-27.3c-13.3-7.8-14-18.7-12.6-33.1c2.5-26,4.8-52.9,0.9-78.4c-2.9-19-0.1-30.8,15.7-40.3
		c7.5-4.5,7.6-10.6,3.4-17.8c-6.6-11.1-12.8-22.5-19.4-33.7c-11.2-19.1-11.8-20.2-31.5-11.1c-12.2,5.6-19.7,2.3-28.1-5.8
		c-20-19.2-43-34.4-69.6-41.8c-19.1-5.3-28.5-15.1-27.9-34.7c0.3-9.1-4.9-11.6-13.4-11.3c-14.8,0.5-29.7,1-44.5-0.1
		c-13.6-1-20.9,2.3-20.6,17.6c0.2,13.6-6.5,21-20.5,26.6c-23.3,9.4-46.5,21.1-66.4,36.3c-15.6,11.8-27.9,21-47.2,9.9
		c-7.7-4.4-14.2-2.1-18.6,7.4c-5.8,12.5-13.1,24.5-20.8,36.1c-7.1,10.7-6,18.6,4.9,25.2c14.1,8.4,16,20.1,12.4,35.6
		c-6.2,26.4-6.5,53.2,0.1,79.9C61.3,301.9,61.8,307.5,63.2,316.1z"
        />
        <path
          d="M255.4,279.8c0,12.8,0.9,25.4-0.4,37.7c-0.6,5.2-6.1,9.8-9.4,14.6c-3.6-4.5-10-8.8-10.2-13.4c-1.4-25.9-1.5-51.8-1.8-77.8
		c-0.2-15.3,0.6-30.6-0.3-45.9c-0.6-11,4.5-16.1,14.1-16.1c16.6,0,33.2,1.4,49.8,2.3c1.3,0.1,2.7,0.7,3.9,1.3
		c25.1,12.2,21.6,35.4,21.2,56.9c-0.4,19.9-9.4,32.4-26.4,35.9C283.3,277.9,270.3,278.2,255.4,279.8z M256.4,256
		c12.3,0,23,0.8,33.5-0.4c3.7-0.5,9.4-5.7,9.8-9.2c1.3-11.4,1.3-23.2,0.1-34.6c-0.4-4-5.6-10.1-9.3-10.6
		c-11.2-1.4-22.7-0.5-34.2-0.5C256.4,219.7,256.4,237.2,256.4,256z"
        />
        <path
          d="M211.8,322.4c-2.7,2.7-6.6,9.8-10.2,9.6c-4.2-0.2-10.4-6-11.8-10.5c-6.1-20.8-5.6-21-26.7-21c-3.7,0-7.5,0.6-11.1-0.1
		c-12.1-2.2-16.6,4.1-18.3,14.8c-0.7,4.5-1.3,10-4.1,12.9c-2.5,2.5-10,4.1-11.9,2.4c-3.3-3-6.4-9.4-5.5-13.4
		c3.4-15.3,8.3-30.3,13-45.3c8.3-27,16.6-53.9,25.6-80.7c1.5-4.4,6.7-7.6,10.2-11.4c3.7,3.7,9.3,6.8,10.7,11.3
		c13.2,40.9,25.7,82,38.4,123.1C210.6,315.8,210.8,317.6,211.8,322.4z M177.4,277.4c-4.7-16-9-30.6-13.2-45.2
		c-1.3-0.3-2.6-0.7-3.9-1c-5.3,15.1-10.7,30.2-16.3,46.1C156.2,277.4,165.6,277.4,177.4,277.4z"
        />
        <path
          d="M364,333.9c-0.9-0.1-5.7,0.1-9.9-1.5c-8.7-3.2-11.6-8.1-4.3-16.6c3.4-4,5.8-10.2,5.9-15.5c0.6-24,0.3-48.1,0.2-72.1
		c0-12.3,2.4-25.2-7.9-35.2c-0.6-0.6-1.2-1.9-1-2.5c1.5-3.4,3.2-9.5,5-9.5c10.8-0.4,21.6,0.5,32.3,1.8c0.7,0.1,1.2,8,0.4,11.9
		c-1.7,8.2-6,16.1-6.3,24.2c-0.9,23.6-0.9,47.2,0,70.7c0.4,9.7,3.1,19.6,6.4,28.8C389,329.9,385.1,333.9,364,333.9z"
        />
      </g>
    </svg>
  );
};

export default RestfulApiIcon;
