const ReactIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#5ED3F3"
        d="M227.2,362c9.4,0.4,19,0.7,28.7,0.7c9.8,0,19.5-0.2,29-0.7c-9.5,12.3-18.9,23.2-28.8,33.5l0.1-0.1
	c-9.9-10.2-19.4-21.1-28.3-32.5L227.2,362z M129.9,348.4c18.5,4.7,41,8.7,64.1,11.1l2.2,0.2c14.1,19.3,28.3,36.4,43.6,52.4l-0.2-0.2
	c-22.4,25-52.6,42.5-86.7,48.8l-0.9,0.1c-0.1,0-0.1,0-0.2,0c-4.2,0-8.2-1-11.7-2.9l0.1,0.1c-14.2-8.1-20.4-39.1-15.6-79
	C125.7,369.2,127.6,358.9,129.9,348.4L129.9,348.4z M382.3,348.1c2,8.1,3.9,18.4,5.3,28.9l0.2,1.5c4.9,39.8-1.1,70.8-15.2,79.1
	c-3.1,1.7-6.9,2.8-10.9,2.8c-0.4,0-0.8,0-1.2,0h0.1c-35-6.4-65.2-23.8-87.6-48.5l-0.1-0.1c15-15.8,29.1-32.7,42.1-50.6l1-1.4
	c25.2-2.6,47.8-6.7,69.8-12.2L382.3,348.1L382.3,348.1z M149.9,284.4c4.3,8.4,8.7,16.7,13.7,25.1c4.9,8.3,9.9,16.4,15,24.4
	c-14.8-2.2-29.1-4.9-42.8-8.2C139.6,312.2,144.4,298.3,149.9,284.4L149.9,284.4z M362,284c5.7,14,10.4,28,14.4,41.6
	c-13.7,3.3-28.1,6.2-43,8.3c5.1-8,10.2-16.3,15-24.7C353.3,300.8,357.8,292.3,362,284L362,284z M112.9,192.7
	c7.4,25.2,15.4,46.3,24.7,66.7l-1.3-3.1c-8,17.2-15.8,38.1-22.3,59.7l-0.9,3.5c-12.1-3.7-21.7-7.3-31.2-11.4l2,0.8
	C47,293.1,23.1,272.4,23.1,256c0-16.4,23.9-37.2,60.8-52.8C92.9,199.4,102.8,195.9,112.9,192.7L112.9,192.7z M398.7,192.6
	c10.3,3.2,20.1,6.8,29.3,10.6c36.9,15.8,60.8,36.4,60.8,52.8c-0.1,16.4-24,37.1-60.9,52.8c-9,3.8-18.8,7.3-28.9,10.5
	c-7.5-25.3-15.5-46.4-24.8-66.8l1.3,3.2c7.9-17.1,15.8-38.1,22.2-59.7L398.7,192.6L398.7,192.6z M333.3,178.2
	c14.8,2.2,29.1,4.9,42.8,8.3c-3.8,13.5-8.6,27.3-14.1,41.2c-4.3-8.3-8.7-16.7-13.7-25C343.6,194.3,338.5,186.2,333.3,178.2
	L333.3,178.2z M178.5,178.2c-5.1,8-10.2,16.3-15,24.8c-4.8,8.3-9.3,16.7-13.6,25c-5.7-14-10.4-27.9-14.4-41.5
	C149.1,183.3,163.5,180.4,178.5,178.2L178.5,178.2z M256,172.8c15.8,0,31.5,0.7,47,2c8.7,12.4,17.1,25.6,25.2,39.7
	c7.9,13.7,15.2,27.5,21.7,41.5c-6.6,14-13.8,27.9-21.6,41.6c-8.1,14.1-16.5,27.5-25.2,39.9c-15.5,1.3-31.3,2.1-47.2,2.1
	c-15.8,0-31.5-0.8-47-2c-8.7-12.4-17.1-25.7-25.2-39.7c-7.9-13.7-15.2-27.5-21.7-41.5c6.5-14,13.8-28,21.6-41.7
	c8.1-14.1,16.5-27.4,25.2-39.8C224.4,173.5,240.1,172.7,256,172.8L256,172.8z M255.7,116.8c9.9,10.2,19.4,21.1,28.3,32.5l0.7,0.9
	c-9.4-0.4-19-0.7-28.7-0.7c-9.8,0-19.5,0.2-29,0.7c9.5-12.3,18.9-23.2,28.8-33.5L255.7,116.8L255.7,116.8z M151.5,51.7
	c35,6.4,65.2,23.8,87.6,48.5l0.1,0.1c-15,15.8-29.1,32.8-42.1,50.7l-1,1.4c-25.2,2.6-47.8,6.6-69.8,12.2l3.3-0.7
	c-2.4-10.4-4.2-20.6-5.4-30.3c-4.9-39.8,1.1-70.8,15.2-79.1C143,52.9,147.1,51.9,151.5,51.7L151.5,51.7L151.5,51.7z M360,51.3v0.1
	c0.2,0,0.5,0,0.8,0c4.1,0,7.9,1,11.3,2.8l-0.1-0.1c14.2,8.1,20.4,39.1,15.6,79c-1.1,9.8-3,20.2-5.3,30.7
	c-18.5-4.7-41.1-8.8-64.1-11.2l-2.2-0.2c-14.1-19.4-28.3-36.4-43.6-52.4l0.2,0.2c22.4-24.9,52.5-42.5,86.6-48.8L360,51.3L360,51.3z
	 M360.1,28c-41.7,6.3-77.8,26.6-104.1,55.8l-0.1,0.2c-26.5-29.2-62.6-49.3-103.3-55.4l-1-0.1c-0.2,0-0.5,0-0.8,0
	c-8.4,0-16.3,2.2-23.1,6.1l0.2-0.1C98.6,51.3,92,104,107.2,170.2C42.2,190.2,0,222.3,0,256.1c0,33.9,42.5,66.1,107.6,86
	c-15,66.4-8.3,119.2,21.1,136.1c6.5,3.7,14.2,5.9,22.4,5.9c0.4,0,0.8,0,1.1,0h-0.1c41.7-6.3,77.8-26.6,104.1-55.8l0.1-0.2
	c26.5,29.2,62.6,49.3,103.3,55.4l1,0.1c0.3,0,0.6,0,0.9,0c8.3,0,16.2-2.2,22.9-6l-0.2,0.1c29.3-16.9,35.9-69.6,20.8-135.8
	c64.7-19.9,106.9-52,106.9-85.9c0-33.9-42.5-66.1-107.6-86c15-66.3,8.3-119.2-21.1-136.1c-6.5-3.7-14.3-5.9-22.7-5.9
	C360.4,28,360.2,28,360.1,28L360.1,28L360.1,28z M303.6,256.1c0,26.3-21.4,47.7-47.7,47.7s-47.7-21.4-47.7-47.7s21.4-47.7,47.7-47.7
	c13.2,0,25.1,5.3,33.7,14l0,0C298.2,231,303.6,242.9,303.6,256.1L303.6,256.1C303.6,256.1,303.6,256.1,303.6,256.1z"
      />
    </svg>
  );
};

export default ReactIcon;
