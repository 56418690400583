export const navLinks = [
  {
    name: "About",
    url: "/#about"
  },
  {
    name: "Projects",
    url: "/#projects"
  },
  {
    name: "Contact",
    url: "/#contact"
  }
];
