const GitIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g>
        <path
          d="M480.1,178.8L332.8,31.3c-40.6-40-105-41.9-147.9-4.5l60.2,60.3c20.8-7.4,43.7,3.4,51.2,24.3c1.6,4.6,2.4,9.4,2.3,14.2v1.9
		c-0.2,4.2-1.1,8.3-2.6,12.2l78.4,76.9c3.8-1.5,7.8-2.4,11.8-2.6c23,0,41.6,18.7,41.6,41.7c0,23-18.6,41.7-41.6,41.7
		c-23,0-41.6-18.7-41.6-41.7v0l0,0c0.2-4.1,1.1-8.1,2.6-11.9l-72-72.1v167.6c14.2,6.8,23.3,21.1,23.4,36.9c0,23-18.6,41.7-41.6,41.7
		c-23,0-41.6-18.7-41.6-41.7c0.1-14.9,8.3-28.5,21.5-35.6V161.8c-12.7-6.8-20.9-19.9-21.5-34.3c0.2-4.1,1.1-8.1,2.6-11.9l-60.8-60.9
		L31.9,178.8C-10.5,221-10.6,289.6,31.5,332c0.1,0.1,0.2,0.2,0.3,0.3l0,0l147.3,147.8c42.2,42.4,110.7,42.5,153.1,0.3
		c0.1-0.1,0.2-0.2,0.3-0.3l0,0l147.6-147.5c42.4-42.2,42.5-110.8,0.3-153.2c-0.1-0.1-0.2-0.2-0.3-0.3V178.8z"
        />
      </g>
    </svg>
  );
};

export default GitIcon;
