const NodeJsIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className="html-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <g id="Layer_2_00000103256358589393171820000005490606759978781620_">
        <g id="invisible_box">
          <rect fill="none" width="512" height="512" />
        </g>
        <g id="Q3_icons">
          <g>
            <path
              fill="#509941"
              d="M451.2,125.9L274.1,25.6c-11.5-5.4-24.8-5.4-36.3,0L60.8,125.9c-11.1,6.4-18,18.1-18.1,30.9v198.4
				c0.2,12.8,7.1,24.5,18.1,30.9l46.9,26.7c12.3,7.2,26.3,10.9,40.5,10.7c33.1,0,52.3-20.3,52.3-54.4V173.9c0-2.9-2.4-5.3-5.3-5.3
				h-22.4c-2.9,0-5.3,2.4-5.3,5.3v195.2c0,16-16,30.9-41.6,18.1l-49.1-27.7c-1.3-1-2.1-2.6-2.1-4.3V156.8c0.1-2,0.8-3.9,2.1-5.3
				l176-99.2h6.4l176,99.2c1.3,1.5,2.1,3.4,2.1,5.3v198.4c0,1.7-0.8,3.3-2.1,4.3l-176,99.2c-2,1.1-4.4,1.1-6.4,0L208,433.1
				c-1.2-1.1-3.1-1.1-4.3,0l-26.7,11.7c-3.2,1.1-7.5,2.1,1.1,7.5l59.7,33.1c11,7.2,25.3,7.2,36.3,0l177.1-99.2
				c11.1-6.4,18-18.1,18.1-30.9V156.8C469.2,144,462.3,132.3,451.2,125.9z"
            />
            <path
              fill="#509941"
              d="M310.4,323.2c-46.9,0-56.5-10.7-60.8-33.1c0-2.4-1.9-4.3-4.3-4.3h-23.5c-2.4,0-4.3,1.9-4.3,4.3
				c0,28.8,16,64,92.8,64c55.5,0,87.5-21.3,87.5-58.7s-25.6-48-80-54.4c-54.4-6.4-59.7-10.7-59.7-23.5c0-12.8,4.3-23.5,44.8-23.5
				s50.1,7.5,55.5,30.9c0.5,2.5,2.8,4.3,5.3,4.3h22.4l4.3-2.1c0.8-0.9,1.2-2,1.1-3.2c-3.2-41.6-32-60.8-88.5-60.8s-80,21.3-80,55.5
				s29.9,48,77.9,53.3s62.9,12.8,62.9,24.5S347.7,323.2,310.4,323.2z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NodeJsIcon;
